.PodcastsList {
  margin: 1rem 0 0.6rem 0;
}

.list_header {
  display: grid;
  grid-template-columns: 3fr 1fr;
  border-bottom: 1px solid var(--grey-light);
}

.list_header > div {
  padding: 0.2rem 0.3rem;
}

.list_row {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.list_row > div {
  padding: 0.3rem 0.3rem;
}
