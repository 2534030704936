:root{
  /** https://coolors.co/008080-e2e6f3-4f5369-ff6b6b-031927 */
  --teal: #008080;
  --alice-blue: #F1F3F9;
  --independence: #4F5369;
  --tart-orange: #FF4747;
  --rich-black-fogra-29: #031927;

  --text-color: var(--independence);
  --primary-color: var(--teal);
  --secondary-color: var(--tart-orange);
  --background-color: var(--alice-blue);

  /** Aux Colors */
  --white-transparent: #ffffff5e;
  --white-semitransparent: rgba(255, 255, 255, 0.85);
  --grey-light: #4f536954;
  --primary-transparent: #00808017;
  --secondary-transparent: #FF474750;
  --error-bg: #ff4747c7;

  --radius-small: 5px;
}

body {
  margin: 0;
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.1rem;
  line-height: 1.45;
  color: var(--text-color);
  background-color: var(--background-color);
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5 {
  font-family: 'DM Serif Display', serif;
  line-height: 1.35;
  letter-spacing: 0.04em;
  margin: 0;
  padding: 0;
}

h1 {
  color: var(--primary-color);
}
h2 {
  color: var(--rich-black-fogra-29);
  margin-bottom: 0.5rem;
}
h3, h4, h5 {
  color: var(--independence);
  margin-bottom: 0.3rem;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.9rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.2rem;
}

p {
  margin: 0;
  margin-bottom: 1em;
}

/** Generic elements */
/** Special effects for links */
main a:not(.button) {
  display: inline-block;
  position: relative;
  color: var(--tart-orange);
  text-decoration: none;
}
main a:not(.button):after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color:  var(--tart-orange);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

main a:not(.button):hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

/** Generic button style */
a.button,
button.button {
  text-decoration: none;
  display: inline-flex;
  border: none;
  border-radius: 1em;
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1em;
  font-family: 'DM Serif Display';
  transition: all 0.2s ease-in-out;
  opacity: 0.7;
  letter-spacing: 0.02em;
}

button.button[disabled] {
  background-color: var(--text-color);
  pointer-events: none;
}

a.button:hover,
button.button:hover {
  opacity: 1;
}

.button svg {
  margin-right: 0.3rem;
}

a.button_secondary,
button.button_secondary {
  background-color: var(--secondary-color);
}

a.button_cancel,
button.button_cancel {
  background-color: var(--independence);
}

/** App forms structure */
.jarvis_form {
  display: grid;
  grid-template-columns: 1fr;
}
@media screen and (min-width: 768px) {
  .form_group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .group_2_1 {
    grid-template-columns: 2fr 1fr;
  }
}

.form_element {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.form_element textarea,
.form_element input[type="text"],
.form_element input[type="text"],
.form_element input[type="date"],
.form_element input[type="time"],
.form_element select {
  margin-top: 0.2rem;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.2em;
  padding: 0.5rem 0.7rem;
  border: 1px solid var(--white-semitransparent);
  border-radius: var(--radius-small);
  color: inherit;
  background-color: var(--white-semitransparent);
  height: 2.5rem;
}
.form_element textarea {
  height: 5em;
  resize: vertical;
}
.form_element textarea.longer {
  height: 10em;
}
.form_element input[type="file"] {
  margin-top: 0.2rem;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.2rem;
  padding: 0.5rem 0;
}

.form_element.checkbox {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 2.2em;
}

.control_group {
  display: flex;
  align-items: center;
  justify-content: stretch;
}
.form_actions {
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.form_actions > button {
  margin-right: 1rem;
  font-size: 1.3rem;
  padding: 0.5rem 1.5rem;
}
.form_element .control_group > button {
  margin-left: 1rem;
}

.composite_group {
  padding: 0.5rem 0 0.5rem 1rem;
  border-left: 0.2rem solid var(--secondary-transparent);
}

/** Action groups outside forms */
.action_group {
  margin: 2rem 0;
}
.action_group > a,
.action_group > button {
  margin-right: 1rem;
}

/** Customizing a bit default audio player */
audio {
  filter: sepia(40%) saturate(70%) grayscale(1) contrast(99%);
}
