.Episode {
  padding: 1rem;
  background-color: var(--white-semitransparent);
  border-radius: var(--radius-small);
  display: flex;
  flex-direction: row;
}

@media screen and (min-width: 768px) {
  .Episode {
    flex-direction: column;
  }
}

.podcast_title h3 {
  color: var(--primary-color);
}

.podcast_title:hover:after {
  display: none;
}

.podcast_info {
  flex-grow: 1;
}

.pub_date {
  font-size: 0.9rem;
  margin-bottom: 0.5em;
}

.actions {
  text-align: left;
  margin: 0 0 0.5rem 0;
}
@media screen and (min-width: 768px) {
  .actions {
    margin: 1rem 0 0.5rem 0;
  }
}

.action {
  display: block;
}
