.SquareImageResponsive {
  position: relative;
}
.SquareImageResponsive::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.SquareImageResponsive > img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
