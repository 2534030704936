.list_wrapper {
  display: grid;
  gap: 1rem;
}

@media screen and (min-width: 768px) {
  .list_wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: 1200px) {
  .list_wrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
