.login_wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content:center;
    background-image: url('/public/soundwave.jpg');
    background-size: cover;
}

.login_form {
    box-sizing: border-box;
    border: 1px solid lightgrey;
    background-color: var(--white-semitransparent);
    border-radius: 20px;
    padding: 30px 50px 50px 50px;
    width: 100%;
    margin: 0 20px;
    margin-top: -6rem;
}

@media (min-width: 768px) {
    .login_form {
        width: 500px;
    }
}

.login_form_title {
    margin: 15px auto 35px;
    max-width: 250px;
    display: block;
}

.form {
    display: flex;
    flex-direction: column;
}
.form_element_input {
    border: 1px solid var(--independence);
    background-color: var(--white-semitransparent);
    border-radius: 20px;
    padding: 10px 20px;
    margin-bottom: 20px;
    font-family: 'DM Sans', sans-serif;
    font-size: 1rem;
}

.form_element_input::placeholder {
    color: darkgrey;
}

.form_element_submit {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    border: none;
    border-radius: 1em;
    padding: 10px;
    background-color: var(--primary-color);
    color: #fff;
    width: 100%;
    cursor: pointer;
    font-size: 1.6rem;
    line-height: 1em;
    font-family: 'DM Serif Display';
    transition: all 0.2s ease-in-out;
}

.form_element_submit svg {
    margin-right: 0.5rem;
}

.form_element_submit:hover {
    background-color: var(--independence);
}

.error_message {
    border-radius: 5px;
    color: #fff;
    border: 1px solid darkred;
    background-color: lightcoral;
    padding: 5px;
    margin-bottom: 20px;
}
