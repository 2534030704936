.Breadcrumbs {
  list-style-type: none;
  padding: 0.2rem 0.5rem;
  margin: 0 0 1.5rem 0; 
  display: flex;
  background-color: var(--primary-transparent);
  border-radius: var(--radius-small);
  font-size: 0.9rem;
}

.separator {
  margin: 0 0.4rem;
}

a.breadcrumb_link:not(.button) {
  color: var(--text-color);
}

a.breadcrumb_link:not(.button):hover {
  text-decoration: underline;
}

a.breadcrumb_link:not(.button)::after {
  display: none;
}
