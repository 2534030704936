.header {
    background-color: var(--white-transparent);
    padding: 0 20px;
    height: 4rem;
    display: flex;
    align-items: center;
    z-index: 10;

    box-shadow: 0px 5px 15px -3px rgba(0,0,0,0.2);
}

.header a {
    text-decoration: none;
}

.home_link {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    display: block;
    height: 50px;
    width: auto;
}

.sidebar {
    position: fixed;
    top: 0;
    grid-column: 1/2;
    width: 100%;
    height: 100vh;
    padding: 20px;
    background-color: var(--primary-color);
    z-index: 1;
}

.hidden_sidebar {
    left: 100vw;
}

.sidebar a {
    color: #fff;
    text-decoration: none;
    padding: 1rem 0;
    border-bottom: 1px solid var(--white-transparent);
}

.main {
    justify-self: center;
    grid-column: 2/3;
    padding: 1rem 1.5rem 3.5rem 1.5rem;
    width: 100%;
}

.open_button {
    position: absolute;
    color: var(--primary-color);
    background-color: var(--primary-transparent);
    width: 2.7rem;
    height: 2.5rem;
    top: 0.7rem;
    right: 1.5rem;
    padding: 0.3rem;
    border-radius: var(--radius-small);
}

.open_button svg {
    width: 100%;
    height: 100%;
}

.close_menu {
    color: #fff;
    display: flex;
    justify-content: flex-end;
}

.close_menu svg {
    width: 2rem;
    height: 2rem;
}

@media (min-width: 768px) {
    .content_wrapper {
        display: grid;
        grid-template-columns: 2.2fr 6fr;
    }

    .header {
        grid-column: 1/3 ;
    }
    
    .sidebar {
        position: relative;
        grid-column: 1/2;
        border-bottom: none;
        padding: 20px;
        height: 100%;
    }

    .hidden_sidebar {
        left: initial;
    }

    .main {
        padding: 1rem 3rem 3.5rem 3rem;
        min-height: calc(100vh - 64px);
    }

    .open_button {
        display: none;
    }

    .close_menu {
        display: none;
    }
}

@media (min-width: 1200px) {
    .content_wrapper {
        grid-template-columns: 1.5fr 6fr;
    }
}


