.widget_rail {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin: 2rem 0;
}

@media screen and (min-width: 768px){
  .widget_rail {
    grid-template-columns: repeat(3, 1fr);
  }
}

.widget {
  padding: 0.5rem 1rem 1rem;
  border-radius: var(--radius-small);
  background: var(--white-semitransparent);
  box-shadow: 0px 5px 15px -3px rgb(0 0 0 / 20%);
}

.widget_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
