.navbar {
    display: flex;
    flex-direction: column;
}

.navbar a {
    display: flex;
    transition: padding 0.3s ease-in-out;
}

.navbar a:hover {
    padding-left: 0.5rem;
}

.navbar a svg {
    margin-right: 0.5em;
    margin-top: 0.1rem;
    font-size: 1.3rem;
}
