.PodcastHeader {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .PodcastHeader {
    flex-direction: row;
    justify-content: space-between;
  }
}
.cover {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: var(--radius-small);
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 768px) {
  .cover {
    display: block;
    width: 280px;
    height: 280px;
  }
}

@media screen and (min-width: 1200px) {
  .cover {
    width: 362px;
    height: 362px;
  }
}


@media screen and (min-width: 768px) {
  .left_side {
    margin-right: 2rem;
    flex-grow: 1;
  }
}

.podcast_name {
  margin-bottom: 1.5rem;
}

.category {
  margin-bottom: 1rem;
}

.authors {
  margin-bottom: 1rem;
}

.description {
  background-color: var(--white-semitransparent);
  border-radius: var(--radius-small);
  padding: 1rem;
  margin-top: 1rem;
  width: 100%;
}

.description p {
  margin-bottom: 0.5rem;
}

.description p:last-child {
  margin-bottom: 0;
}

.icons_data > div {
  display: flex;
  flex-wrap: wrap;
}

.icons_data > div > svg {
  margin-right: 0.4rem;
  width: 1.2em;
  height: 1.2em;
}
